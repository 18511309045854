import {FaChevronLeft, FaChevronRight, FaEdit, FaRegClock, FaTimes, FaTrash, FaUserCircle} from "react-icons/fa";
import {BsCheckLg, BsDiagram2, BsExclamationLg, BsFillExclamationTriangleFill, BsTelephone} from "react-icons/bs";
import {GiHamburgerMenu} from "react-icons/gi";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";
import {RiArrowDownSLine, RiArrowUpSLine, RiSearchLine} from "react-icons/ri";
import {FaXmark} from "react-icons/fa6";
import {AiOutlineCaretDown} from "react-icons/ai";
import {FiCopy, FiEye, FiEyeOff} from "react-icons/fi";

export const Icons =
{
    edit: <FaEdit />,
    delete: <FaTrash />,
    close: <FaTimes />,
    clock: <FaRegClock />,
    error: <BsFillExclamationTriangleFill />,
    success: <BsCheckLg />,
    warning: <BsExclamationLg />,
    arrowLeft: <FaChevronLeft />,
    arrowRight: <FaChevronRight />,
    hamburgerMenu: <GiHamburgerMenu />,
    user: <FaUserCircle color="#B8B8B8"/>,
    descArrow: <TiArrowSortedDown />,
    ascArrow: <TiArrowSortedUp />,
    unsorted: <TiArrowUnsorted />,
    arrowDown: <RiArrowDownSLine />,
    arrowUp: <RiArrowUpSLine />,
    search: <RiSearchLine />,
    crossDelete: <FaXmark />,
    phone: <BsTelephone />,
    hierarchy: <BsDiagram2 />,
    childrenExists: <AiOutlineCaretDown viewBox="0 310 1024 1024" />,
    copy: <FiCopy />,
    show: <FiEye />,
    hide: <FiEyeOff />
}
