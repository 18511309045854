import React, {FC} from 'react';
import styles from "./css/Search.module.css"
import {Icons} from "../helpers/Icons";

interface IProps
{
    placeholder: string;
    onChange: (e: string) => void;
    disabled?: boolean;
}

const Search: FC<IProps> = ({placeholder, onChange, disabled}) =>
{
    return (
        <div className={`${styles.search} ${disabled ? styles.disabled : null}`}>
            {Icons.search}
            <input type="search" placeholder={placeholder} onChange={e => onChange(e.target.value)} />
        </div>
    );
}

export default Search;
