import React, {useContext, useEffect} from 'react';
import './index.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {loginRequest} from "./authConfig";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal,
    useMsalAuthentication
} from "@azure/msal-react";
import {InteractionRequiredAuthError} from "@azure/msal-common";
import {RequestAccessToken} from "./helpers/APIEnpoints";
import Context from "./helpers/Context";
import './i18n';
import {useTranslation} from "react-i18next";
import {InteractionType} from "@azure/msal-browser";
import NavBar from "./components/NavBar";
import LocalPasswordRetriever from "./components/LocalPasswordRetriever";
import ControlPanel from "./components/ControlPanel";
import {isAdmin} from "./helpers/checkers";
function App()
{
    const { instance, accounts } = useMsal();
    const {setGroupsID, setAccessToken, accessToken, groupsID} = useContext(Context);
    const isAuthenticated = useIsAuthenticated();
    const {t} = useTranslation();
    const {login, error} = useMsalAuthentication(InteractionType.Silent, loginRequest);

    useEffect(() =>
    {
        (async () =>
        {
            if (error instanceof InteractionRequiredAuthError) await login(InteractionType.Redirect, loginRequest);
        })();
    }, [error]);

    useEffect(() =>
    {
        if(isAuthenticated)
        {
            RequestAccessToken(accounts, instance, setAccessToken);
            if(accounts[0].idTokenClaims !== undefined && Array.isArray(accounts[0].idTokenClaims.groups)) setGroupsID(accounts[0].idTokenClaims.groups);
        }
    }, [isAuthenticated]);

  return (
      <Router>
          <NavBar />
          {accessToken !== "" ?
              <div className="main-container">
                  <Routes>
                      <Route path="/" element={<LocalPasswordRetriever />} />
                      <Route path="/control-panel" element={
                          <>
                              <AuthenticatedTemplate>
                                  {isAdmin(groupsID) ? <ControlPanel /> : <p>{t("app.accessError")}</p>}
                              </AuthenticatedTemplate>
                              <UnauthenticatedTemplate>
                                  <p>{t("app.unAuthError")}</p>
                              </UnauthenticatedTemplate>
                          </>
                      } />
                  </Routes>
              </div>
          : <p>{t("app.unAuthError")}</p>}
      </Router>
  );
}

export default App;
