import {loginRequest} from "../authConfig";
import {AccountInfo, IPublicClientApplication} from "@azure/msal-browser";
import {Dispatch, SetStateAction} from "react";
import {notificationDispatch, userDevice} from "./types";
import {v4} from "uuid";
import {t} from "i18next";
import {allDevices, macosDevivces} from "./interfaces";

export const RequestAccessToken = (accounts: AccountInfo[], instance: IPublicClientApplication, setAccessToken: Dispatch<SetStateAction<string>>): void =>
{
    const request =
        {
            ...loginRequest,
            account: accounts[0]
        };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) =>
    {
        setAccessToken(response.accessToken);
    }).catch((e) =>
    {
        instance.acquireTokenPopup(request).then((response) =>
        {
            setAccessToken(response.accessToken);
        });
    });
}

export const getUserDevices = async (accessToken: string, notificationDispatch: Dispatch<notificationDispatch>): Promise<userDevice[]> =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/device/`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json()).then(data =>
            {
                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return [];
    }
}

export const getUserDevicePasswordByID = async (deviceID: string, accessToken: string, notificationDispatch: Dispatch<notificationDispatch>, setIsError: Dispatch<SetStateAction<boolean>>): Promise<string> =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/password/${deviceID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json())
            .then(data =>
            {
                if(data.error)
                {
                    setIsError(true);
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                    return btoa(data.loc ? t(`backEnd.${data.loc}`) : data.error);
                }

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return "";
    }
}

export const getMacSecretKeyByID = async (deviceID: string, accessToken: string, notificationDispatch: Dispatch<notificationDispatch>): Promise<string> =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/secretKey/${deviceID}`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json())
            .then(data =>
            {
                if(data.error)
                {
                    notificationDispatch({
                        type: "ADD_NOTIFICATION",
                        payload:
                            {
                                id: v4(),
                                type: "error",
                                message: data.loc ? t(`backEnd.${data.loc}`) : data.error
                            }
                    })
                    return btoa(data.loc ? t(`backEnd.${data.loc}`) : data.error);
                }

                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return "";
    }
}

export const getMacOsDevices = async (accessToken: string, notificationDispatch: Dispatch<notificationDispatch>): Promise<macosDevivces[] | string> =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/password/macos/secret-keys`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json())
            .then(data =>
            {
                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return "";
    }
}

export const getAllDevices = async (accessToken: string, notificationDispatch: Dispatch<notificationDispatch>): Promise<allDevices[] | string> =>
{
    try
    {
        return await fetch(`${process.env.REACT_APP_API_URL}/device/all`,
            {
                method: "GET",
                headers: {'Authorization': `Bearer ${accessToken}`}
            })
            .then(response => response.json())
            .then(data =>
            {
                return data;
            });
    } catch (err)
    {
        notificationDispatch({
            type: "ADD_NOTIFICATION",
            payload:
                {
                    id: v4(),
                    type: "error",
                    message: (err as string)
                }
        })

        return "";
    }
}
