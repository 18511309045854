import {Dispatch, SetStateAction} from "react";

export const getFirstLastName = (fullName: string, lastName: boolean = false) =>
{
    if(lastName) return fullName.split(" ")[1];

    return fullName.split(" ")[0];
}

export const resetFilter = (setLowLevelFilter: Dispatch<SetStateAction<string>>, arrayFilters?: Dispatch<SetStateAction<any[]>>[], stringFilters?: Dispatch<SetStateAction<string>>[]) =>
{
    if(arrayFilters)
    {
        arrayFilters.map(filter => filter([]))
    }

    if(stringFilters)
    {
        stringFilters.map(filter => filter("All"))
    }

    setLowLevelFilter("");
}

export const handleSorting = (arrayToSort: any[], sortField: string, sortOrder: string, setSortedArray: Dispatch<SetStateAction<any[]>>, setSortField: Dispatch<SetStateAction<string>>) =>
{
    setSortField(sortField);

    const sorted = [...arrayToSort].sort((a, b) =>
    {
        let properA = sortField.includes(".") ? a[sortField.split(".")[0]][sortField.split(".")[1]] : a[sortField];
        let properB = sortField.includes(".") ? b[sortField.split(".")[0]][sortField.split(".")[1]] : b[sortField];

        if (properA === null) return 1;
        if (properB === null) return -1;
        if (properA === null && properB === null) return 0;
        return (
            properA.toString().localeCompare(properB.toString(), "en",
                {
                    numeric: true,
                }) * (sortOrder === "asc" ? 1 : -1)
        );
    });

    setSortedArray(sorted);
};
export const upperCaseFirstLetter = (string: string) =>
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const generateRandomHiddenPassLen = (maxLength: number) =>
{
    let hiddenPass = "";

    for(let i = 0; i < Math.floor(Math.random() * (maxLength - 16) + 16); i++)
    {
        hiddenPass = hiddenPass + "*"
    }

    return hiddenPass;
}
