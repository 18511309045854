import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./authConfig";
import {Configuration, PublicClientApplication} from "@azure/msal-browser";
import {ContextProvider} from "./helpers/Context";
import NotificationProvider from "./utilities/Notifications/NotificationProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig as Configuration);


root.render(
  <React.StrictMode>
      <ContextProvider>
          <NotificationProvider>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
          </NotificationProvider>
      </ContextProvider>
  </React.StrictMode>
);
