import React, {useContext, useEffect, useState} from 'react';
import {userDevice} from "../helpers/types";
import Context from "../helpers/Context";
import {getUserDevicePasswordByID, getUserDevices} from "../helpers/APIEnpoints";
import Button from "../utilities/Button";
import styles from './css/LocalPasswordRetriever.module.css';
import {Icons} from "../helpers/Icons";
import {useTranslation} from "react-i18next";
import {v4} from "uuid";
import {generateRandomHiddenPassLen} from "../helpers/helperFunctions";

function LocalPasswordRetriever()
{
    const {notificationDispatch, accessToken} = useContext(Context);
    const [userDevices, setUserDevices] = useState<userDevice[]>([]);
    const [password, setPassword] = useState<string>("");
    const [hiddenPassword, setHiddenPassword] = useState<string>("");
    const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);

    const [isDataFetching, setIsDataFetching] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const {t} = useTranslation();

    useEffect(() =>
    {
        (async () =>
        {
            const devices = await getUserDevices(accessToken, notificationDispatch);

            if(devices)
            {
                setUserDevices(devices);
                await handleGetPassword(devices[0].azureADDeviceId);
            }

            setIsDataFetching(false)
        })();
    }, []);

    const handleGetPassword = async (deviceID: string) =>
    {
        setIsError(false)
        setIsDataFetching(true);
        setPassword("");
        setHiddenPassword("");
        const devicePass = await getUserDevicePasswordByID(deviceID, accessToken, notificationDispatch, setIsError);
        if(devicePass && devicePass.length)
        {
            setPassword(atob(devicePass));
            setHiddenPassword(generateRandomHiddenPassLen(32))
        }
        setIsDataFetching(false)
    }

    const copyPasswordClipboard = (password: string) =>
    {
        navigator.clipboard.writeText(password).then(r =>
        {
            notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "success",
                        message: t("app.passwordCopied")
                    }
            })
        }).catch(err =>
        {
            notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: err.message
                    }
            })
        })
    }

    return (
        <div className={styles.container}>
            <label>
                {t("app.selectDevice")}
                <select disabled={isDataFetching} onChange={e => handleGetPassword(e.target.value)}>
                    {userDevices.map(device =>
                        <option key={device.id} value={device.azureADDeviceId}>{device.deviceName}</option>
                    )}
                </select>
            </label>

            <div className={styles.password_container}>
                <span className={styles.header}>{t("app.yourPassword")}</span>
                <div className={styles.password} style={{color: isError ? "var(--error)" : "#000"}}>{isPasswordHidden ? hiddenPassword : password} {(password.length && !isError) ?
                    <>
                        <button title={isPasswordHidden ? t("app.showPassword") : t("app.hidePassword")}
                                onClick={() => setIsPasswordHidden(current => !current)}>{isPasswordHidden ? Icons.show : Icons.hide}</button>
                        <button title={t("app.copyToClipboard")} onClick={() => copyPasswordClipboard(password)}>{Icons.copy}</button>
                    </>
                    : null}</div>

            </div>
        </div>
    );
}

export default LocalPasswordRetriever;
