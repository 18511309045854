import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector  from "i18next-browser-languagedetector";
import eng from "./localization/en.json";
import fin from "./localization/fi.json";

const resources =
{
    "en-US": {
        translation: eng,
    },
    "fi-FI": {
        translation: fin,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en-US",
        supportedLngs: ["fi-FI", "en-US"],
        detection:
        {
            order: ["querystring", "localStorage", "sessionStorage", "cookie", "navigator"],
        },
        interpolation:
        {
            escapeValue: false
        },
        react:
        {
            useSuspense: false,
        },
    });

export default i18n;
